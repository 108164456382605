// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SettingsCard.vue render when created  1`] = `
<div
  class="settings-card"
>
  <div
    class="settings-card__heading"
  >
     
    <div
      class="sc-heading__actions"
    >
      <span
        class="sc-heading__actions--edit"
      >
        Edit
      </span>
    </div>
  </div>
   
  <div
    class="sc-content"
  >
    <div
      class="sc-content__content"
    />
  </div>
</div>
`;
